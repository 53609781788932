.footer {
  margin-top: -10px;
  padding: 2em;
  border-radius: 15px 15px 0 0;
  background: white;

  &__list {
    display: flex;

    & > * + * {
      margin: 0 0 0 1em;
    }
  }

  &__link {
    color: #134094;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 600;
  }
}
