.containerAussen {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  scroll-behavior: smooth;
}

.header {
  height: 100px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: left;
  align-items: center;
}

.header img {
  width: 200px;
  height: 100px;
  margin-left: 30px;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #42b6e6;
  border-radius: 15px 15px 0 0;
  background-image: linear-gradient(to top, #42b6e6, #aad3e4);
}

.main {
  flex: 1;
}

.containerForm {
  width: 100%;
  max-width: 900px;
}

@include for-tablet-landscape-up {
  .containerForm {
    max-width: none;
    width: 900px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  padding: 0 1em;
}

.form-headline {
  margin: 2em 0 0.25em;
  color: #134094;
}

.formularHead {
  padding: 30px;
  margin-bottom: 10px;
  padding-left: 10px;
  font-weight: bold;
  background-color: #134094;
  color: white;
  margin-block-start: 0;
}

p {
  color: #134094;
}

textarea {
  border: 1px solid #134094;
  border-radius: 10px;
  height: 80px;
  resize: vertical;
  font-size: 1rem;
  padding: 1em;
}
.textarea:focus {
  outline: none;
}
.textareaerror {
  border: crimson 1px solid;
  border-radius: 10px;
  height: 80px;
  resize: vertical;
  font-size: 1rem;
  padding: 1em;
}
.errorMessageTextarea {
  color: crimson;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 20px;
  font-size: small;
}

/* Buttons */

.button-container {
  display: flex;
  justify-content: center;
  margin: 10px;

  .small {
    width: 140px;
    margin-bottom: 20px;
  }
}

/* Textarea */

.container__checkbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .container__checkbox_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      margin-right: 10px;
      color: #134094;
      display: flex;
      align-items: center;
      .checkbox {
        margin-right: 30px;
      }
    }
  }
  .errorMessageCheckbox {
    color: crimson;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 20px;
    font-size: small;
    align-self: flex-start;
    margin-left: 55px;
  }
}

@include for-tablet-portrait-up {
  .container {
    padding: 4em 2em;
  }
  .containerForm {
    padding: 30px;
    margin: 30px;
    border-radius: 10px;
    border: 1px solid white;
    background-color: white;
  }
  .formularHead {
    padding-left: 40px;
    margin: -30px;
    margin-bottom: 30px;
    border-radius: 10px 10px 0 0;
  }
  /* Buttons */
  .button-container {
    .large {
      width: 200px;
    }
  }
}

@include for-desktop-up {
  .containerForm {
    padding: 30px;
    margin: 30px;
    border-radius: 10px;
    border: 1px solid white;
    background-color: white;
  }

  .form {
    padding: 0;
  }

  /* Überschrift des gesamten Formulars */

  .formularHead {
    margin: -30px;
    margin-bottom: 30px;
    border-radius: 10px 10px 0 0;
  }
}

.fade {
  &-container {
    display: none;
    flex-direction: column;
    opacity: 0;
    height: 0;
  }

  &-enter {
    display: flex;
    opacity: 0;
    height: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 300ms;
    height: auto;
    display: flex;
  }

  &-enter-done {
    opacity: 1;
    height: auto;
    display: flex;
  }

  &-exit {
    opacity: 1;
    height: auto;
  }

  &-exit-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  &-exit-done {
    opacity: 0;
  }
}

.subtitle {
  margin: 5em 0 0;
}

.loader {
  display: flex;
  align-items: center;
}
