label {
  font-weight: bold;
  margin: 1em 0 0.25em 0.5em;
  color: #134094;
}

input {
  border: 1px solid #134094;
  border-radius: 10px;
  padding: 0.75em;
  font-size: 1rem;
  color: #134094;
}
::placeholder {
  color: #747f92;
  font-size: 1rem;
}

input:focus {
  outline: none;
}

.errorMessage {
  color: crimson;
  font-size: small;
  margin: 0.25em 0 0 1em;
}

.hasError {
  border: crimson 1px solid;
}
