.main {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #42b6e6;
  border-radius: 15px 15px 0 0;
  background-image: linear-gradient(to top, #42b6e6, #aad3e4);
  padding-bottom: 5em;
}
.container_daten_alle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  height: auto;

  h1 {
    color: #134094;
    margin: 1.5em 0 1em;
  }

  h2 {
    text-align: center;
    color: #134094;
    margin: 1.25em 0 0.5em;
  }
}

.container_daten {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 25px;
  border-radius: 15px;
  max-width: 600px;
  height: auto;
  background-color: white;

  p {
    margin: 5px;
    color: #134094;
    line-height: 1.4;
    font-size: 1.125rem;
  }

  .subtitle {
    margin: 2em 0 0;
  }

  .subtitle + .subtitle {
    margin: 0.5em 0 0;
  }

  .daten_ueberschrift {
    margin-top: 20px;
  }
}

@include for-tablet-portrait-up {
  .main {
    height: 100vh;
  }
  .container_daten_alle {
    width: 80%;
  }
  .container_daten {
    width: 80%;
    background-color: white;
    background-image: none;
  }
}
