.button {
  height: 40px;
  margin-top: 20px;
  border-style: none;
  border-radius: 5px;
  background-color: #134094;
  border: 2px #134094 solid;
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 1.25rem;
  cursor: pointer;
}
