.containerDropdown {
  display: grid;
  position: relative;
  width: 100%;
}

.dropdown {
  border: 1px solid #134094;
  border-radius: 10px;
  font-size: 1rem;
  padding: 0.75em;
  margin: 0;
  background-color: white;
  max-width: 100%;
  color: #134094;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  grid-row: 2;
}
.dropdown:focus {
  outline: none;
}
.select {
  margin-top: 0.25em;
  position: absolute;
  width: 100%;
  z-index: 22;
  grid-row: 3;
}

ul {
  background-color: white;
  color: #134094;
  grid-row: 3;
  z-index: 20;
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
}

ul:focus {
  outline: 0;
  border-radius: 10px;
  border: solid 1px #134094;
}

li {
  padding: 0.5em 1em;
  cursor: pointer;
}

.dropdown_error_message {
  color: crimson;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 20px;
  font-size: small;
}

.error_dropdown {
  border: crimson 1px solid;
  border-radius: 10px;
  padding: 15px;
  margin: 10px;
  background-color: white;
  max-width: 100%;
  color: #134094;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  position: relative;
  grid-row: 2;
}

.dropdown[disabled],
.dropdown_error[disabled] {
  cursor: not-allowed;
}
